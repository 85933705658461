import React, { useState, useEffect, useContext, useCallback } from "react";
import { ControlContext } from "./ControlContext";
import { UserContext } from "./UserContext";
import { getApolloContext, gql, useMutation, useQuery } from "@apollo/client";
import JSZip from "jszip";
import { saveAs } from "file-saver";

const FOLDERS = gql`
  query GetFolders($showFoldersInProjectProjectId: String!) {
    showFoldersInProject(project_id: $showFoldersInProjectProjectId) {
      name
      _id
    }
  }
`;

const GET_ENGINES = gql`
  query GetEngines {
    enginesForUser {
      name
      _id
      key
    }
  }
`;

const ADD_FOLDER = gql`
  mutation AddFolder($name: String!, $project: String!) {
    addShowFolder(name: $name, project: $project) {
      name
      _id
    }
  }
`;

const UPDATE_FOLDER = gql`
  mutation updateShowFolder(
    $updateFolderId: String!
    $updateFolderName: String
  ) {
    updateShowFolder(id: $updateFolderId, name: $updateFolderName) {
      _id
      name
    }
  }
`;

const DELETE_FOLDER = gql`
  mutation deleteShowFolder($folderId: String!) {
    deleteShowFolder(id: $folderId) {
      _id
    }
  }
`;

const SHOWS = gql`
  query showsInProject($project_id: String!) {
    showsInProject(project_id: $project_id) {
      _id
      name
      folder {
        _id
        name
      }
    }
  }
`;

const UPDATE_SHOW = gql`
  mutation updateShow($updateShowId: String!, $updateShowName: String) {
    updateShow(id: $updateShowId, name: $updateShowName) {
      _id
      name
    }
  }
`;

const DUPLICATE_SHOW = gql`
  mutation duplicateShow($id: String!, $name: String) {
    duplicateShow(id: $id, name: $name) {
      _id
      name
      folder {
        name
        _id
      }
    }
  }
`;
const DELETE_SHOW = gql`
  mutation deleteShow($showId: String!) {
    deleteShow(id: $showId)
  }
`;

const ADD_SHOW = gql`
  mutation addShow($name: String!, $folder: String!, $project: String!) {
    addShow(name: $name, folder: $folder, project: $project) {
      _id
      name
      folder {
        _id
        name
      }
    }
  }
`;

const SCENES = gql`
  query getScenesInProject($scenesInProjectProjectId: String!) {
    scenesInProject(project_id: $scenesInProjectProjectId) {
      _id
      name

      data
    }
  }
`;
const PAGES_IN_SHOW = gql`
  query getPagesInShow($showId: String!) {
    pagesInShow(show: $showId) {
      _id
      number
      description
      duration
      engine
      show {
        _id
        name
      }
      template {
        _id
        name
      }
      edits
    }
  }
`;

const ADD_PAGE = gql`
  mutation addPage(
    $template: String!
    $show: String!
    $description: String
    $number: Int
    $duration: Int
    $edits: JSON
  ) {
    addPage(
      template: $template
      show: $show
      description: $description
      number: $number
      edits: $edits
      duration: $duration
    ) {
      _id
      number
      description
      edits
      duration
      show {
        _id
        name
      }
      template {
        _id
        name
      }
    }
  }
`;

const UPDATE_PAGE = gql`
  mutation updatePage(
    $id: String!
    $description: String
    $edits: JSON
    $duration: Int
    $engine: String
    $template: String
  ) {
    updatePage(
      id: $id
      description: $description
      edits: $edits
      duration: $duration
      engine: $engine
      template: $template
    ) {
      _id
      number
      description
      edits
      duration
      engine
      show {
        _id
        name
      }
      template {
        _id
        name
      }
    }
  }
`;

const DELETE_PAGE = gql`
  mutation deletePage($id: String!) {
    deletePage(id: $id)
  }
`;
let doPreview = true;

const ProjectContext = React.createContext([{}, () => {}]);

const ProjectProvider = (props) => {
  const { preview, clearPreview, setThemeName, buildTemplate } =
    useContext(ControlContext);
  const { client } = useContext(getApolloContext());
  const { loggedIn } = useContext(UserContext);
  const [scenes, setScenes] = useState([]);
  const [selectedScene, setSelectedScene] = useState(null);
  const [selectedSceneId, setSelectedSceneId] = useState(null);
  const [selectedPage, setSelectedPage] = useState(null);
  const [pages, setPages] = useState([]);
  const [edits, setEdits] = useState([]);
  const [shows, setShows] = useState([]);
  const [project, setProject] = useState();
  const [selectedShow, setSelectedShow] = useState(null);
  const [selectedEdit, setSelectedEdit] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [dataDrive, setDataDrive] = useState([]);
  const [folders, setFolders] = useState([]);
  const [engines, setEngines] = useState();
  const [selectedShowFolder, setSelectedShowFolder] = useState(null);
  const [loading, setLoading] = useState(true);
  const colours = ["#32cda9", "#6e32cd", "#cd3281"];

  useEffect(() => {
    try {
      if (buildTemplate && buildTemplate.scene !== "CLOCK") {
        doPreview = false;
        let edits = [];

        let scene = scenes.find((s) => {
          let template_names = [];
          if (buildTemplate.app === "football") {
            template_names = s.data?.football?.templates;
          } else if (buildTemplate.app === "rugby") {
            template_names = s.data?.rugby?.templates;
          } else if (buildTemplate.app) {
            template_names = s.data?.[buildTemplate.app]?.templates;
          }
          return (
            s.name === buildTemplate.scene ||
            template_names?.findIndex((t) => t.name === buildTemplate.scene) >
              -1
          );
        });

        getEdits(scene.data.scene.children, edits);
        edits = edits.map((e) => {
          let data = buildTemplate.data.find(
            (pe) =>
              e.name === pe.name ||
              e.control?.data?.variables?.findIndex(
                (v) => v.template === buildTemplate.scene && v.field === pe.name
              ) > -1
          );

          let newObj = {
            ...e,
            ...(data && data.value),
          };

          if (newObj.tint && !newObj.colour) {
            newObj.colour = newObj.tint;
          }

          if (newObj.position) {
            newObj.x = newObj.position.x;
            newObj.y = newObj.position.y;
          }

          return newObj;
        });
        debugger;
        edits = edits
          .sort((a, b) => parseInt(a.control.id) - b.control.id)
          .map((e) => {
            return {
              ...e,
              original_src: e.src,
              original_image: e.image,
            };
          });

        selectScene(scene);
        setEdits(edits);
        setSelectedEdit(edits[0]);
      }
    } catch (err) {}
  }, [buildTemplate]);

  useEffect(() => {
    if (engines) {
      window.engines = engines;
    }
  }, [engines]);

  useEffect(() => {
    if (loggedIn && project) {
      client
        .query({
          query: GET_ENGINES,
        })
        .then((response) => {
          setEngines(
            response.data.enginesForUser.map((e) => ({
              ...e,
              status: "connected",
            }))
          );
        })
        .catch((err) => console.error(err));
      client
        .query({
          query: FOLDERS,
          variables: {
            showFoldersInProjectProjectId: project?._id,
          },
        })
        .then((response) => {
          setFolders(response.data.showFoldersInProject);
          setSelectedShowFolder(response.data.showFoldersInProject[0]);
        })
        .catch((err) => console.error(err));

      client
        .query({
          query: SCENES,
          variables: { scenesInProjectProjectId: project?._id },
        })
        .then((response) => {
          setScenes(
            response.data.scenesInProject
              .filter((s) => s.data?.live_enabled && !s.data?.archived)
              .map((scene) => ({
                ...scene,
                name: scene.name,
                data: {
                  ...scene.data,
                  name: scene.name,
                },
              }))
          );
          client
            .query({
              query: SHOWS,
              variables: { project_id: project?._id },
            })
            .then((response) => {
              setLoading(false);
              setShows(response.data?.showsInProject || []);
            })
            .catch((err) => console.error(err));
        })
        .catch((err) => console.error(err));
    }
  }, [loggedIn, client, project]);

  useEffect(() => {
    if (selectedShow && selectedShow.name !== "") {
      changeSelectedShow(selectedShow);
    }
  }, [selectedShow]);

  function changeSelectedShow(selectedShow) {
    setThemeName(selectedShow.theme);

    client
      .query({
        query: PAGES_IN_SHOW,
        fetchPolicy: "network-only",
        variables: {
          showId: selectedShow._id,
        },
      })
      .then((response) => {
        let data = [...response.data.pagesInShow];
        data = data.map((p) => {
          let page = { ...p };
          page.name = p.description;
          page.template = scenes.find(
            (scene) => scene._id === page.template?._id
          );
          return page;
        });
        setPages(data);
      })
      .catch((err) => console.error(err));
  }

  useEffect(() => {
    if (doPreview) {
      setSelectedEdit(null);

      if (selectedScene) {
        let edits = [];

        getEdits(selectedScene.data?.scene?.children, edits);
        if (selectedPage && selectedPage.template.name === selectedScene.name) {
          edits = edits
            .sort((a, b) => parseInt(a.control.id) - b.control.id)
            .map((e) => {
              return {
                ...e,
                original_src: e.src,
                original_image: e.image,
                ...selectedPage.edits.find(
                  (pe) => e.control.name === pe.control.name
                ),
              };
            });

          setEdits(edits);
          setSelectedEdit(edits[0]);
        } else if (!selectedPage) {
          edits = edits.sort((a, b) => parseInt(a.control.id) - b.control.id);
          edits = edits.map((e) => {
            return {
              ...e,
              original_src: e.src,
              original_image: e.image,
            };
          });
          setEdits(edits);
          setSelectedEdit(edits[0]);
        }
      } else {
        setEdits([]);
      }
    }
  }, [selectedScene, selectedPage]);

  function getEdits(children, items) {
    for (let i = 0; i < children.length; i++) {
      let item = { ...children[i] };
      if (
        item.type === "TEXT" ||
        item.type === "IMAGE" ||
        item.type === "VIDEO" ||
        item.type === "GROUP" ||
        item.type === "CLOCK" ||
        item.type === "RECTANGLE" ||
        item.type === "CIRCLE"
      ) {
        if (item.type === "GROUP") {
          if (item.control.name !== "") {
            items.push({ ...item });
          }
          getEdits(item.children, items);
        } else if (item.control.name !== "") {
          items.push({ ...item });
        }
      } else if (item.children.length > 0) {
        getEdits(item.children, items);
      }
    }
  }

  function selectPage(page) {
    doPreview = true;
    setSelectedScene(page.template);
    setSelectedPage(page);
    setPageNumber(page.number);
    setSelectedSceneId(page.template._id);
  }

  function selectScene(scene) {
    setSelectedPage(null);
    setSelectedScene(scene);
    setSelectedSceneId(scene?._id);
  }

  useEffect(() => {
    if (selectedScene && edits) {
      let tl = selectedScene.data.timelines.find((tl) => tl.name === "IN");
      if (doPreview) {
        preview(
          selectedScene.name,
          tl.duration - 1,
          edits,
          null,
          selectedPage?.engine
        );
      }
      doPreview = true;
    }
  }, [edits]);

  function selectPageNumber(number) {
    let page = pages.find((page) => parseInt(page.number) === parseInt(number));
    if (page) {
      selectPage({ ...page });
    }
  }

  function nextEdit() {
    let index = edits
      .sort((a, b) => {
        return parseInt(a.control.id) - parseInt(b.control.id);
      })
      .findIndex((edit) => edit === selectedEdit);
    if (index > -1) {
      if (index < edits.length - 1) {
        setSelectedEdit(edits[index + 1]);
      } else {
        setSelectedEdit(edits[0]);
      }
    }
  }

  function getItemByUUID(uuid, children) {
    for (let i = 0; i < children.length; i++) {
      let item = children[i];
      if (item.uuid === uuid) {
        return item;
      } else if (item.children.length > 0) {
        let child = getItemByUUID(uuid, item.children);
        if (child) {
          return child;
        }
      }
    }
  }

  function savePage(pageNumber, new_edits, description, scene) {
    return new Promise((resolve, reject) => {
      let page = pages.find((p) => p.number === pageNumber);
      let saveEdits = [...edits];
      if (new_edits) {
        saveEdits = new_edits;
      }
      saveEdits = saveEdits.map((item) => {
        let templateItem = getItemByUUID(
          item.uuid,
          scene?.data?.scene?.children || selectedScene.data.scene.children
        );

        let e = {
          type: item.type,
          uuid: item.uuid,
          name: item.name,
          control: item.control,
        };
        if (item.fontSize && templateItem.style._fontSize !== item.fontSize) {
          e = { ...e, fontStyle: item.fontStyle, fontSize: item.fontSize };
        }
        if (templateItem.text !== item.text) {
          e = { ...e, text: item.text };
        }

        if (templateItem.colour !== item.colour) {
          e = { ...e, colour: item.colour };
        }
        if (templateItem.x !== item.x) {
          e = { ...e, x: item.x };
        }
        if (templateItem.y !== item.y) {
          e = { ...e, y: item.y };
        }
        if (templateItem.width !== item.width) {
          e = { ...e, width: item.width };
        }
        if (templateItem.height !== item.height) {
          e = { ...e, height: item.height };
        }

        if (templateItem.type === "IMAGE" && templateItem.src !== item.image) {
          e = { ...e, image: item.image };
        }

        if (templateItem.type === "VIDEO" && templateItem.src !== item.video) {
          e = { ...e, video: item.video };
        }

        if (templateItem.visible !== item.visible) {
          e = { ...e, visible: item.visible };
        }

        return e;
      });
      if (!page) {
        return client
          .mutate({
            mutation: ADD_PAGE,
            variables: {
              description: description || "",
              show: selectedShow._id,
              template: scene?._id || selectedSceneId,
              number: pageNumber,
              edits: saveEdits,
            },
          })
          .then((response) => {
            let page = { ...response.data.addPage };
            page.template = scenes.find(
              (scene) => scene._id === page.template._id
            );

            let newPages = [...pages, page];
            setPages(newPages);
            selectPage(page);
            resolve();
          })
          .catch((err) => {
            console.error(err);
            reject(err);
          });
      } else {
        return client
          .mutate({
            mutation: UPDATE_PAGE,
            variables: {
              id: page._id,
              template: scene?._id || selectedSceneId,
              edits: saveEdits,
            },
          })
          .then((response) => {
            let newPages = [...pages];
            let index = newPages.findIndex(
              (p) => p._id === response.data.updatePage._id
            );
            newPages[index] = response.data.updatePage;
            newPages[index].name = newPages[index].description || "";
            newPages[index].template = scenes.find(
              (scene) => scene._id === response.data.updatePage.template._id
            );
            setPages(newPages);
            resolve();
          })
          .catch((err) => {
            console.error(err);
            reject(err);
          });
      }
    });
  }

  function setPageSaveNumber() {
    let nextPageNumber = pages.reduce((prev, item) => {
      if (item.number > prev) {
        return item.number;
      }
      return prev;
    }, 0);
    setPageNumber(nextPageNumber + 1);
    return nextPageNumber + 1;
  }

  function nextPage() {
    let idx = pages
      .sort((a, b) => parseInt(a.number) - parseInt(b.number))
      .findIndex((p) => parseInt(p.number) === parseInt(pageNumber));
    if (idx + 1 < pages.length) {
      selectPage(pages[idx + 1]);
    }
  }

  function nextPageInPlaylist(pageNum) {
    let idx = pages
      .sort((a, b) => parseInt(a.number) - parseInt(b.number))
      .findIndex(
        (p) => parseInt(p.duration) && parseInt(p.number) > parseInt(pageNum)
      );

    if (idx !== -1 && idx < pages.length) {
      selectPage(pages[idx]);
      let page = pages[idx];
      let edits = [];
      getEdits(page.template.data.scene.children, edits);
      edits = edits
        .sort((a, b) => parseInt(a.control.id) - b.control.id)
        .map((e) => {
          return {
            ...e,
            ...page.edits.find((pe) => e.control.name === pe.control.name),
          };
        });

      return { page: pages[idx], edits: edits };
    }
  }

  function clear() {
    setSelectedPage(null);
    setSelectedScene(null);
    clearPreview();
  }

  function selectShow(show) {
    clear();
    if (selectedShow && show.name !== selectedShow.name) {
      setPages([]);
    }
    setSelectedShow(show);
  }

  const createShow = useCallback(
    (showName, theme) => {
      return client
        .mutate({
          mutation: ADD_SHOW,
          variables: {
            name: showName,
            folder: selectedShowFolder._id,
            project: project?._id,
          },
        })
        .then((response) => {
          let newShows = [...shows, response.data.addShow];
          setShows(newShows);

          setSelectedShow(
            newShows.find((s) => s._id === response.data.addShow?._id)
          );
          return response.data.addShow;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [shows, selectedShowFolder]
  );

  function deleteShow(show_id) {
    client
      .mutate({
        mutation: DELETE_SHOW,
        variables: { showId: show_id },
      })
      .then((response) => {
        let newShows = [...shows.filter((show) => show._id !== show_id)];
        setShows(newShows);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function renameShow(show_id, showName, theme) {
    client
      .mutate({
        mutation: UPDATE_SHOW,
        variables: { updateShowId: show_id, updateShowName: showName },
      })
      .then((response) => {
        let newShows = [...shows];
        let showIndex = shows.findIndex((show) => show._id === show_id);
        if (showIndex > -1) {
          newShows[showIndex] = { ...shows[showIndex], name: showName };
        }
        setShows(newShows);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function renameFolder(folder_id, folderName) {
    client
      .mutate({
        mutation: UPDATE_FOLDER,
        variables: { updateFolderId: folder_id, updateFolderName: folderName },
      })
      .then((response) => {
        let newFolders = [
          ...folders.filter(
            (f) => f._id !== response.data.updateShowFolder?._id
          ),
          response.data.updateShowFolder,
        ];
        setFolders(newFolders);
        setSelectedShowFolder(newFolders.find((f) => f.name === folderName));
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function deleteFolder(folder_id) {
    client
      .mutate({
        mutation: DELETE_FOLDER,
        variables: { folderId: folder_id },
      })
      .then((response) => {
        let newFolders = [...folders.filter((f) => f._id !== folder_id)];
        setFolders(newFolders);
        setSelectedShowFolder(newFolders?.[0]);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function archiveShow(showName, theme, archived) {}

  function renamePage(number, description) {
    let newPages = [...pages];
    let page = newPages.find((page) => page.number === number);
    page.name = description;
    client
      .mutate({
        mutation: UPDATE_PAGE,
        variables: { id: page._id, description: description },
      })
      .then((response) => {
        setPages(newPages);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function setDuration(number, duration) {
    let newPages = [...pages];
    let page = newPages.find((page) => page.number === number);
    page.duration = parseInt(duration) || 0;
    client
      .mutate({
        mutation: UPDATE_PAGE,
        variables: { id: page._id, duration: page.duration },
      })
      .then((response) => {
        setPages(newPages);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function setEngine(number, engine) {
    let newPages = [...pages];
    let page = newPages.find((page) => page.number === number);
    page.engine = engine || "1";
    client
      .mutate({
        mutation: UPDATE_PAGE,
        variables: { id: page._id, engine: page.engine },
      })
      .then((response) => {
        setPages(newPages);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function deletePage(pageNumber) {
    let page = pages.find((page) => page.number === pageNumber);

    client
      .mutate({
        mutation: DELETE_PAGE,
        variables: { id: page._id },
      })
      .then((response) => {
        let newPages = [...pages];
        let index = pages.findIndex((page) => page.number === pageNumber);
        newPages.splice(index, 1);

        setPages(newPages);
        if (selectedPage && selectedPage.number === pageNumber) {
          clear();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function duplicateShow(id, oldShowName) {
    client
      .mutate({
        mutation: DUPLICATE_SHOW,
        variables: { id: id, name: oldShowName + "_COPY" },
      })
      .then((response) => {
        let newShows = [...shows, response.data.duplicateShow];
        setShows(newShows);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function createShowFolder(name) {
    client
      .mutate({
        mutation: ADD_FOLDER,
        variables: { name: name, project: project?._id },
      })
      .then((response) => {
        let newFolders = [...folders, response.data.addShowFolder];
        setFolders(newFolders);
        setSelectedShowFolder(newFolders.find((f) => f.name === name));
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function updateEngine(engine) {
    let newEngines = [...engines.filter((e) => e._id !== engine._id)];
    newEngines.push(engine);
    setEngines(newEngines);
  }

  function exportShow(id, show_name) {
    client
      .query({
        query: PAGES_IN_SHOW,
        fetchPolicy: "network-only",
        variables: {
          showId: id,
        },
      })
      .then((response) => {
        let data = [...response.data.pagesInShow];
        const zip = new JSZip();
        for (let i = 0; i < data?.length; i++) {
          let page = data[i];
          zip.file("pages/" + page.number + ".json", JSON.stringify(page));
        }
        zip.generateAsync({ type: "blob" }).then(function (content) {
          saveAs(content, show_name + ".studio");
        });
      })
      .catch((err) => console.error(err));
  }

  function importPages(show, pages) {
    let promises = [];
    promises.push(
      client
        .query({
          query: PAGES_IN_SHOW,
          fetchPolicy: "network-only",
          variables: {
            showId: show._id,
          },
        })
        .then((response) => {
          let data = [...response.data.pagesInShow];

          for (let i = 0; i < pages.length; i++) {
            let page = pages[i];

            let template = scenes.find((s) => s.name === page?.template?.name);
            if (template) {
              let old_page = data?.find((p) => p.number === page.number);
              if (!old_page) {
                //new page
                promises.push(
                  client.mutate({
                    mutation: ADD_PAGE,
                    variables: {
                      description: page.description,
                      show: show._id,
                      template: template?._id,
                      number: page.number,
                      edits: page.edits,
                      engine: page.engine,
                    },
                  })
                );
              } else {
                //update page
                promises.push(
                  client.mutate({
                    mutation: UPDATE_PAGE,
                    variables: {
                      id: old_page._id,
                      template: template?._id,
                      edits: page.edits,
                      duration: page.duration,
                      description: page.description,
                      engine: page.engine,
                    },
                  })
                );
              }
            }
          }
        })
        .catch((err) => console.error(err))
    );
    return Promise.all(promises);
  }

  async function importShow(pages) {
    debugger;
    let new_show = await createShow(pages[0]?.show?.name);

    let promises = [];

    for (let i = 0; i < pages.length; i++) {
      let page = pages[i];

      let template = scenes.find((s) => s.name === page?.template?.name);
      if (template) {
        //new page
        promises.push(
          client.mutate({
            mutation: ADD_PAGE,
            variables: {
              description: page.description,
              show: new_show._id,
              template: template?._id,
              number: page.number,
              edits: page.edits,
            },
          })
        );
      }
    }

    await Promise.all(promises);
    return new_show;
  }

  return (
    <ProjectContext.Provider
      value={{
        scenes,
        pages,
        edits,
        selectScene,
        selectedScene,
        selectedEdit,
        setSelectedEdit,
        pageNumber,
        setPageNumber,
        selectPage,
        selectedPage,
        nextEdit,
        savePage,
        setPageSaveNumber,
        nextPage,
        selectedShow,
        shows,
        clear,
        selectShow,
        createShow,
        deleteShow,
        renameShow,
        deletePage,
        duplicateShow,
        selectPageNumber,
        renamePage,
        setDataDrive,
        archiveShow,
        selectedShowFolder,
        setSelectedShowFolder,
        createShowFolder,
        setPages,
        setDuration,
        nextPageInPlaylist,
        folders,
        getEdits,
        setProject,
        project,
        setSelectedShow,
        engines,
        updateEngine,
        renameFolder,
        deleteFolder,
        loading,
        setEngine,
        exportShow,
        importShow,
        importPages,
        setEdits,
        changeSelectedShow,
      }}
    >
      {props.children}
    </ProjectContext.Provider>
  );
};

export { ProjectContext, ProjectProvider };
